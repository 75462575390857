import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { tracker } from "@pjs/analytics";
import { getBestTextMatches } from "@pjs/utilities";
import { IconWithTextAutoCompleteItem, ButtonClass } from "@pjs/core-ui";
import { createGuid } from "@pjs/utilities";
import { MainType } from "@pjs/asset-core";
import { SELECTOR } from "../../constants/selector.constants";
import { ASSET_CONSTANTS } from "../../constants/asset.constants";
import { isWebAddressLike } from "../../utilities/IsWebAddressLike.function";
import { isEmailLike } from "../../utilities/IsEmailLike.function";
import { from$Promise } from "../../observables/From$Promise.function";
import { withDigest } from "../../react2angular/utilities/WithDigest.function";
import { mapSelectorItemsToAutoCompleteItems } from "./mapSelectorItemsToAutoCompleteItems";
import "../../multiLanguageService/multiLanguageService";
import "../../react2angular/checkbox";
import "../../react2angular/validatedInput";
import "../../react2angular/autocomplete";
import "../../react2angular/buttonComponent";
import "../../utilities/debounce.service";
import "../../react2angular/input";
import "../../fileUpload/directives/fileUpload";
import "../../itemSelector/itemSelector.component";
import "../../spaMenu/selector/services/spaSelectorService";
import "../../utilities/urlService";
import "./LinkerSelector.component";
import template from "./templates/linker-modal.html";

class LinkerModalController extends NgComponent {
    constructor($scope, $http, $timeout, multiLanguageService, baseUrlsFactory, urlService) {
        super();

        this._$scope = $scope;
        this._$http = $http;
        this._$timeout = $timeout;
        this.multiLanguage = multiLanguageService;
        this._urlService = urlService;
        this._selectorEndpoint = baseUrlsFactory.api_base_url + "SharedMenu/GetDefaultItemsForSelector";

        this.isSelectingAsset = false;
        this.closeModal = () => $scope.$parent.hideModal();
        this._formState = {
            hasUserMadeChanges: false,
            valid: this.link !== ""
        };

        this._resourceLabel = this.multiLanguage.getString("pebble_terms.resource");
        this._getResourceLabel = (mainType) => (mainType === MainType.WorkBook || mainType === MainType.Form ? this._resourceLabel : "");
        this._searchFields = [{ getProperty: (item) => item.value, weight: 1 }];

        const isAssetRoute = this.link !== "" && !/^http/i.test(this.link) && this._urlService.isSupportedRoute(this.link);

        this.controls = {
            addressInput: {
                id: createGuid(),
                isValid: (value) => isWebAddressLike(value) || isEmailLike(value),
                label: this.multiLanguage.getString("pebble_editor.plugins.linker.modal.link_aria_label"),
                style: "linker-modal__input",
                onInvalid: withDigest((value) => this._setInvalidLink(value), $scope),
                onValid: withDigest((value) => this._setValidLink(value), $scope),
                value: isAssetRoute ? this._urlService.convertRouteToFullUrl(this.link) : this.link
            },
            cancel: {
                content: this.multiLanguage.getString("buttons.cancel"),
                onClick: withDigest(this.closeModal, $scope),
                style: ButtonClass.Outlined
            },
            confirm: {
                content: this.multiLanguage.getString(this.isEditing ? "pebble_editor.plugins.linker.modal.update_link" : "pebble_editor.plugins.linker.modal.add_link"),
                disabled: true,
                onClick: withDigest(() => this._confirmLink(), $scope),
                style: ButtonClass.Contained
            },
            external: {
                label: this.multiLanguage.getString("pebble_editor.plugins.linker.modal.open_link_in_new_tab"),
                onChange: withDigest((value) => {
                    this.controls.external.value = value;
                    this._updateFormState({ hasUserMadeChanges: true });
                }, $scope),
                value: this.isExternal
            },
            remove: {
                content: this.multiLanguage.getString("pebble_editor.plugins.linker.modal.remove_link"),
                onClick: withDigest(() => {
                    this.onRemove();
                    this.closeModal();
                }, $scope),
                style: ButtonClass.Outlined
            },
            autoComplete: {
                ariaLabel: this.multiLanguage.getString("pebble_editor.plugins.linker.modal.search_aria_label"),
                search: (searchTerm) => from$Promise(this._getSearchResults(searchTerm)),
                onInput: withDigest((value) => (this.controls.autoComplete.textContent = value), $scope),
                onItemSelect: withDigest((item) => this._updateLinkFromAutoComplete(item), $scope),
                openLength: 2,
                renderItem: IconWithTextAutoCompleteItem,
                emptyLabel: this.multiLanguage.getString("auto_complete.no_results"),
                loadingLabel: this.multiLanguage.getString("auto_complete.loading"),
                textContent: "",
                debounceTime: 400
            },
            selector: {
                content: this.multiLanguage.getString("pebble_editor.plugins.linker.modal.more_options"),
                onClick: withDigest(() => (this.isSelectingAsset = true), $scope),
                onItemSelect: (item) => this._updateLinkFromSelector(item),
                onSelectorBack: () => this._cancelSelectingAsset()
            },
            textInput: {
                id: createGuid(),
                label: this.multiLanguage.getString("pebble_editor.plugins.linker.modal.link_text_aria_label"),
                onChange: withDigest((e) => {
                    this.controls.textInput.value = e.target.value;
                    this.controls.textInput.usingPresetTitle = false;
                    this._updateFormState({ hasUserMadeChanges: true });
                }, $scope),
                style: "linker-modal__input",
                usingPresetTitle: false,
                value: this.linkText
            }
        };
    }

    _updateLinkFromAsset(assetId, mainType, title) {
        const isAsset = mainType !== ASSET_CONSTANTS.TYPES.FORM && mainType !== ASSET_CONSTANTS.TYPES.WORKBOOK;
        const routeUrl = this._urlService.createHashUrl(assetId, mainType, isAsset, false);
        const url = this._urlService.convertRouteToFullUrl(routeUrl);

        this._setValidLink(url);
        if (this.controls.textInput.value === "" || this.controls.textInput.usingPresetTitle) {
            this.controls.textInput.value = title;
            this.controls.textInput.usingPresetTitle = true;
        }
    }

    _updateLinkFromAutoComplete(item) {
        this.controls.autoComplete.textContent = "";
        this._updateLinkFromAsset(item.id, item.mainType, item.value);
        this._focusAddressField();
        tracker.trackEvent("CKE5 Linker modal", "Selected from quick-search", item.mainType);
    }

    _updateLinkFromSelector(item) {
        this._updateLinkFromAsset(item.Id, item.MainType, item.Title);
        this.controls.selector.onSelectorBack();
        tracker.trackEvent("CKE5 Linker modal", "Selected from selector", item.MainType);
    }

    _confirmLink() {
        const enteredLink = this.controls.addressInput.value;

        this.onConfirm({
            isExternal: this.controls.external.value,
            link: this._normaliseFinalLink(enteredLink),
            linkText: this.allowTextField && this.controls.textInput.value === "" ? enteredLink : this.controls.textInput.value
        });
        this.closeModal();
    }

    _normaliseFinalLink(link) {
        if (isEmailLike(link)) {
            tracker.trackEvent("CKE5 Linker modal", "Confirm link", "Email");
            return /^mailto:/i.test(link) ? link : `mailto:${link}`;
        }

        const route = this._urlService.getRouteFromFullUrl(link);
        if (route !== "") {
            tracker.trackEvent("CKE5 Linker modal", "Confirm link", "Asset/resource");
            return route;
        }

        tracker.trackEvent("CKE5 Linker modal", "Confirm link", "Url");
        return /^https?:\/\//i.test(link) ? link : `https://${link}`;
    }

    _setInvalidLink(value) {
        this.controls.addressInput.value = value;
        this._updateFormState({ valid: false });
    }

    _setValidLink(value) {
        this.controls.addressInput.value = value;
        this._updateFormState({ valid: value !== "", hasUserMadeChanges: true });
    }

    _updateFormState(partialFormState) {
        Object.assign(this._formState, partialFormState);
        this.controls.confirm.disabled = !this._formState.valid || !this._formState.hasUserMadeChanges;
    }

    _getSearchResults(originalSearchTerms) {
        const searchTerms = originalSearchTerms.trim();
        if (searchTerms.length === 0) {
            return Promise.resolve([]);
        }

        const selectorConfig = {
            ExcludeIds: this.excludedAssets,
            Ownerships: ["ByMe", "WithMe"],
            RequestedAmountPerStore: 8,
            SearchTerms: searchTerms,
            SelectType: SELECTOR.SELECT_TYPE.ASSET_AND_RESOURCE
        };

        return this._$http
            .post(this._selectorEndpoint, selectorConfig)
            .then((r) => {
                const assetsAndResources = r.data[0].Items.concat(r.data[1].Items);
                const autoCompleteItems = mapSelectorItemsToAutoCompleteItems(assetsAndResources, this._getResourceLabel);
                return getBestTextMatches(searchTerms, autoCompleteItems, this._searchFields).slice(0, 8);
            })
            .catch(() => {
                throw new Error(this.multiLanguage.getString("auto_complete.error"));
            });
    }

    _cancelSelectingAsset() {
        this.isSelectingAsset = false;
        this._focusAddressField();
    }

    _focusAddressField() {
        this._$timeout(() => document.getElementById(this.controls.addressInput.id).focus());
    }
}

const linkerModalDefinition = {
    bindings: {
        allowTextField: "<",
        allowAssets: "<",
        excludedAssets: "<",
        isEditing: "<",
        isExternal: "<",
        onConfirm: "<",
        onRemove: "<",
        link: "<",
        linkText: "<"
    },
    template: template,
    controller: LinkerModalController
};

LinkerModalController.$inject = ["$scope", "$http", "$timeout", "multiLanguageService", "baseUrlsFactory", "urlService"];
angularAMD.component("linkerModal", linkerModalDefinition);
