import { useClassRef, useOnMount, useOnValueChange } from "@pjs/react-utilities";
import { JSX, RefObject, useId, useRef, useState } from "react";
import { Immutable, noop } from "@pjs/utilities";
import { flushSync } from "react-dom";
import { filter, from, KillSignal, takeUntil } from "@pjs/observables";
import { focusTrapper } from "../../utils/focus-trapper/FocusTrapper.const";
import { Keys } from "../../enums/Keys";
import { Button } from "../button/Button.component";
import { crossIcon } from "../icon/icons/Cross.icon";
import { animateElement } from "../../utils/animate-element/AnimateElement";
import { coreUiI18n } from "../i18n/CoreUiI18n.const";
import { IDialogStep } from "../dialog-instance/interfaces/IDialogStep";
import { IMultiStepDialogInstance } from "../dialog-instance/interfaces/IMultiStepDialogInstance";
import { Icon } from "../icon/Icon.component";
import { dialogDirector } from "../dialog-director/DialogDirector.const";
import { DialogState } from "../dialog-director/enums/DialogState";
import { BoundaryContext } from "../boundary/Boundary.context";
import { showDialogWithoutStealingFocus } from "./utils/ShowDialogWithoutStealingFocus";
import { IDialogProps } from "./interfaces/IDialogProps";
import { isBottomPositioned } from "./utils/IsBottomPositioned";
import { animateDialogIn } from "./utils/AnimateDialogIn";
import { focusFirstElementInDialog } from "./functions/FocusFirstElementInDialog";
import { animateStepChange } from "./functions/AnimateStepChange";
import { dialogAnimationKeyFrames } from "./consts/DialogAnimationKeyFrames.const";
import "./styles/dialog.css";

const animateToNextStep = async (newStep: IDialogStep<any, any>, setStep: (step: IDialogStep<any, any>) => void, stepElement: RefObject<HTMLElement>): Promise<void> => {
    await animateStepChange(stepElement, () => {
        flushSync(() => {
            setStep(newStep);
        });
    });
};

const animateOutOnCloseState = (instance: IMultiStepDialogInstance<any>, dialogRef: RefObject<HTMLDialogElement>, shouldAnimateFromBottom: boolean, killSignal: KillSignal): void => {
    dialogDirector.applicationState
        .pipe(
            takeUntil(killSignal),
            filter((state) => state === DialogState.CLOSING)
        )
        .subscribe(async () => {
            if (dialogRef.current !== null) {
                await animateElement(dialogRef.current, {
                    duration: 150,
                    easing: "ease-out",
                    keyframes: shouldAnimateFromBottom ? dialogAnimationKeyFrames.fadeOutDown : dialogAnimationKeyFrames.fadeOutUp
                });
                instance.destroy();
            }
        });
};

export function Dialog<T>({ instance: dialogInstance }: IDialogProps<T>): JSX.Element {
    const [step, setStep] = useState(dialogInstance.steps.items[0]);
    const [model, setModel] = useState<Immutable<T>>(dialogInstance.model);

    const dialogRef = useRef<HTMLDialogElement>(null);
    const stepRef = useRef<HTMLDivElement>(null);
    const mainContainerRef = useRef<HTMLDivElement>(null);
    const closeButtonRef = useRef<HTMLButtonElement>(null);
    const dialogKillSignal = useClassRef(KillSignal);

    const labelledById = useId();
    const stepContentDescribedBy = useId();
    const screenReaderElementId = useId();

    const characterUnannouncedByScreenReader = String.fromCharCode(8202);
    const dialogDescribedBy = step === dialogInstance.steps.items[0] ? `${screenReaderElementId} ${stepContentDescribedBy}` : screenReaderElementId;

    useOnMount(() => {
        let removeTrap = noop;
        if (dialogRef.current === null || mainContainerRef.current === null) {
            return;
        }

        showDialogWithoutStealingFocus(dialogRef.current);
        const shouldAnimateFromBottom = isBottomPositioned(mainContainerRef.current);

        from(animateDialogIn(dialogRef.current, shouldAnimateFromBottom))
            .pipe(takeUntil(dialogKillSignal))
            .subscribe(() => {
                removeTrap = focusTrapper.trap(dialogRef.current as HTMLDialogElement);
            });

        dialogInstance.modelChanges.pipe(takeUntil(dialogKillSignal)).subscribe(setModel);
        dialogInstance.steps.changeStream.pipe(takeUntil(dialogKillSignal)).subscribe((newStep) => animateToNextStep(newStep, setStep, stepRef));

        animateOutOnCloseState(dialogInstance, dialogRef, shouldAnimateFromBottom, dialogKillSignal);

        return () => {
            removeTrap();
            dialogKillSignal.send();
        };
    });

    useOnValueChange(
        () => {
            focusFirstElementInDialog(dialogRef, mainContainerRef, closeButtonRef);
        },
        [step],
        true
    );

    const handleDefaultCancel = (): void => {
        if (dialogInstance.dismissAction !== null) {
            dialogInstance.dismissAction(model, dialogInstance);
        }
    };

    return (
        <dialog
            role="dialog"
            ref={dialogRef}
            className="cui-dialog"
            aria-labelledby={labelledById}
            aria-describedby={dialogDescribedBy}
            onKeyDown={(e) => {
                if (e.key === Keys.Escape) {
                    e.preventDefault();
                    handleDefaultCancel();
                }
            }}>
            <div className="cui-dialog__position-container">
                <div ref={mainContainerRef} className={`cui-dialog__main-container cui-dialog__main-container--${dialogInstance.size}`} data-hook="dialog-main-container">
                    <div className="cui-dialog__header-container">
                        <div aria-live={"polite"}>
                            <h1 id={labelledById}>{step.title}</h1>
                        </div>
                        {dialogInstance.dismissAction !== null && (
                            <button
                                ref={closeButtonRef}
                                aria-label={coreUiI18n.getString("dialog.close")}
                                data-hook="dialog-close-button"
                                className="cui-dialog__close-button"
                                onClick={handleDefaultCancel}>
                                <Icon className="cui-dialog__close-icon" source={crossIcon} />
                            </button>
                        )}
                    </div>
                    <div ref={stepRef} className="cui-dialog__content-container">
                        <BoundaryContext.Provider value={{ element: dialogRef }}>
                            <step.component instance={dialogInstance} describedById={stepContentDescribedBy} />
                        </BoundaryContext.Provider>
                    </div>
                    <div data-hook="dialog-action-container" className="cui-dialog__action-container">
                        <div className="cui-dialog__prevent-ios-flex-issue">
                            <div className={"cui-dialog__action-group-container"}>
                                {step.actions.left.length > 0 && (
                                    <div className="cui-dialog__action-group cui-dialog__action-group--left">
                                        {step.actions.left.map((action): JSX.Element => {
                                            return (
                                                <Button
                                                    key={`${action.label}${step.title}`}
                                                    autoFocus={action.autoFocus}
                                                    ariaLabel={action.ariaLabel}
                                                    ariaDescribedBy={action.ariaDescribedBy}
                                                    className={action.buttonClass}
                                                    dataHook={action.dataHook}
                                                    onClick={() => action.action(model, dialogInstance)}
                                                    disabled={action.disabled(model)}>
                                                    {action.label}
                                                </Button>
                                            );
                                        })}
                                    </div>
                                )}
                                <div className={"cui-dialog__action-group cui-dialog__action-group--right"}>
                                    {step.actions.right.map((action): JSX.Element => {
                                        return (
                                            <Button
                                                key={`${action.label}${step.title}`}
                                                autoFocus={action.autoFocus}
                                                ariaLabel={action.ariaLabel}
                                                ariaDescribedBy={action.ariaDescribedBy}
                                                className={action.buttonClass}
                                                dataHook={action.dataHook}
                                                onClick={() => action.action(model, dialogInstance)}
                                                disabled={action.disabled(model)}>
                                                {action.label}
                                            </Button>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id={screenReaderElementId} className="cui-dialog__prevent-jaws-ios-full-content-readout" tabIndex={-1}>
                {characterUnannouncedByScreenReader}
            </div>
        </dialog>
    );
}
