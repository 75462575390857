import { angularAMD } from "@pebblepad/amd";
import { ButtonClass } from "@pjs/core-ui";
import { informationNotice } from "@pjs/core-ui";
import "../../../../utilities/baseUrlsFactory";
import "../../../../multiLanguageService/multiLanguageService";
import "../../../../modal/services/modal";
import "../../../../utilities/helpers";
import "../../../../user/user.service";
import "../../../../ckEditorModule/editors/stickyPersistentCompactMultiLineEditorWithFallback.component";
import "../../../../react2angular/htmlLabelCheckbox";
import "../../../../react2angular/htmlNotice";
import "../../../../react2angular/buttonComponent";
import { withDigest } from "../../../../react2angular/utilities/WithDigest.function";
import template from "../templates/comment-input-box.html";

angularAMD.directive("commentInputBox", CommentInputBox);
CommentInputBox.$inject = [];

angularAMD.controller("CommentInputBoxController", CommentInputBoxController);
CommentInputBoxController.$inject = ["$rootScope", "$compile", "$timeout", "$scope", "baseUrlsFactory", "multiLanguageService", "modal", "helpers", "User"];

//Injectors
var $rootScope, $compile, $timeout, $scope, baseUrlsFactory, multiLanguageService, modal, helpers, User;

function SimpleCommentDto(body, author, isPageComment) {
    this.body = body ?? "";
    this.author = author ?? "";
    this.isPageComment = isPageComment ?? false;
}

//Directive
//==============================================================================================================
function CommentInputBox() {
    return {
        restrict: "E",
        template: template,
        scope: {
            commentBody: "=",
            modifying: "@",
            submitText: "@",
            cancelText: "@",
            onSubmit: "&", //Expects to be called with {body: String, author: String}
            onCancel: "&", //Expects {active: false}
            autoFocus: "@",
            commentTitle: "@?",
            a11ySubmitText: "@",
            a11yCancelText: "@",
            onUpdate: "&",
            editorLabel: "<",
            pageData: "<?",
            userMessage: "<?"
        },
        bindToController: true, //binds directive attributes ($scope values above) directly to the Controller (this.)
        controller: "CommentInputBoxController",
        controllerAs: "vm",
        link: function (scope, element) {
            scope.vm.onInit(element[0]);
        }
    };
}

//Controller
//==============================================================================================================
function CommentInputBoxController(_$rootScope_, _$compile_, _$timeout_, _$scope_, _baseUrlsFactory_, _multiLanguageService_, _modal_, _helpers_, User) {
    $rootScope = _$rootScope_;
    $compile = _$compile_;
    $timeout = _$timeout_;
    $scope = _$scope_;
    baseUrlsFactory = _baseUrlsFactory_;
    modal = _modal_;
    helpers = _helpers_;

    this.buttonClasses = ButtonClass;
    this.multiLanguageService = _multiLanguageService_;
    this.comment = new SimpleCommentDto();
    this.requiresAuthor = User.getDto() === null;
    this.disableButtons = false;
    this.firstChange = true;

    this.elements = {
        commentField: null,
        authorField: null
    };

    this.buttonConfig = {
        type: "button",
        saveDataHook: "post-comment-btn",
        cancelDataHook: "cancel-comment-btn"
    };

    const labelType = this.multiLanguageService.getString("accessibility.notice.type.comment");
    this.noticeOptions = {
        type: informationNotice,
        ariaLabel: this.multiLanguageService.getString("accessibility.notice.labels.information", { type: labelType })
    };

    this.richTextEditorClassName = "rich-text-comment-box";
    this.richTextEditorDataHook = "comment-text-area";
    this.richTextAutoFocus = (this.autoFocus ?? false) && !this.requiresAuthor;
    this.richTextConfig = {
        removePlugins: "colordialog,floatingspace,link,unlink,linker,colorbutton,formatting,stylescombo,format,font,size,justify",
        extraPlugins: "sharedspace",
        startupFocus: this.richTextAutoFocus
    };

    this.originalCommentBody = angular.copy(this.commentBody);
    this.updateCommentBody = this.updateCommentBody.bind(this);

    this.onPageChange = withDigest(this._onPageChange.bind(this), $scope);
    this.validateThenSubmit = withDigest(this._validateThenSubmit.bind(this), $scope);
    this.cancelEdit = withDigest(this._cancelEdit.bind(this), $scope);
}

CommentInputBoxController.prototype._validateThenSubmit = function () {
    this.disableButtons = true;
    if (this.validate()) {
        this.submit();
    } else {
        this.disableButtons = false;
    }
};

CommentInputBoxController.prototype.validate = function () {
    //Check if we have any text aside from spaces / <br/>.
    if (this.validateField(this.comment.body) === false) {
        this.launchErrorModal(this.multiLanguageService.getString("sidebar.asset_comments.no_comment_error_message"));
        return false;
    }

    if (this.comment.body.length > 50000) {
        this.launchErrorModal(this.multiLanguageService.getString("sidebar.asset_comments.comment_too_long"));
        return false;
    }

    return true;
};

CommentInputBoxController.prototype.validateField = function (field) {
    return /\S/g.test(helpers.htmlToPlain(field));
};

CommentInputBoxController.prototype.submit = function () {
    this.onSubmit({ data: new SimpleCommentDto(this.comment.body, this.comment.author, this.comment.isPageComment) })
        .then(() => {
            this._cancelEdit();
        })
        .catch(() => {
            this.launchErrorModal(this.multiLanguageService.getString("sidebar.asset_comments.unable_to_save_comment"));
            this.disableButtons = false;
        });
};

CommentInputBoxController.prototype._cancelEdit = function () {
    this.comment.body = "";
    this.comment.author = "";
    this.comment.isPageComment = false;
    this.commentBody = this.originalCommentBody;
    this.disableButtons = false;
    this.firstChange = true;

    this.updateCommentBody("");
    this.onCancel({ active: false });
    this.onUpdate({ commentInProgress: false, isNewComment: true });
};

CommentInputBoxController.prototype.launchErrorModal = function (message) {
    var modalScope = $rootScope.$new(true);
    modalScope.multiLanguageService = multiLanguageService;
    modalScope.confirmMessage = message;
    modalScope.okButton = this.multiLanguageService.getString("buttons.confirm");
    modalScope.okButtonTitle = this.multiLanguageService.getString("buttons.generic.popup");

    modalScope.onClose = function () {
        modalScope.$destroy();
        modalScope = null;
    };

    return modal.newModal({
        scope: modalScope,
        templateUrl: baseUrlsFactory.shared_component_base_url + "spaMenu/okmessage.lazy.html"
    });
};

CommentInputBoxController.prototype.cacheElements = function (parentElement) {
    this.elements.authorField = parentElement.getElementsByClassName("poster-name")[0];
};

CommentInputBoxController.prototype.focusInField = function () {
    if (!this.requiresAuthor) {
        return;
    }

    return $timeout(
        function () {
            if (this.elements.authorField) {
                this.elements.authorField.focus();
            }
        }.bind(this)
    );
};

CommentInputBoxController.prototype.updateCommentBody = function (content) {
    if (this.firstChange && content !== "") {
        this.firstChange = false;
        this.onUpdate({
            commentInProgress: true,
            isNewComment: true
        });
    }
    this.comment.body = content;
};

CommentInputBoxController.prototype.onInit = function (parentElement) {
    this.comment.body = this.commentBody || "";
    this.comment.isPageComment = false;
    this.cacheElements(parentElement);
    if (this.autoFocus === "true") {
        this.focusInField();
    }
};

CommentInputBoxController.prototype.$onChanges = function (changes) {
    if (changes.pageData !== undefined) {
        this._updateIsPageComment(changes.pageData.previousValue);
    }
};

CommentInputBoxController.prototype._onPageChange = function (isChecked) {
    this.comment.isPageComment = isChecked;
};

CommentInputBoxController.prototype._updateIsPageComment = function (previousPageData) {
    if (this.pageData?.mainAssetId !== previousPageData?.mainAssetId) {
        this.comment.isPageComment = false;
    }
};
