import { angularAMD } from "@pebblepad/amd";
import { informationIcon } from "@pjs/core-ui";
import { TIMEOUT_CONSTANTS } from "../../../constants/timeout.constants";
import "../../../utilities/baseUrlsFactory";
import "../../../utilities/urlService";
import "./criteriaTagger";
import "./criteriaAdvanced";
import "../../../assetStore/services/assetStoreService";
import "../../services/collectionService";
import "../../../extensions/arrayExtensions";
import "../../../utilities/helpers";
import "../../../builder/dataManager/helperService";
import "../../../modal/services/modal";
import "../../../datePicker/datePickerHelper";
import "../../../builder/dataManager/dataManagerService";
import "../../../localChanges/services/localChangesResolver";
import "../../../user/user.service";
import "../../../assetSelector/assetSelector.service";
import template from "../templates/criteria-manager.html";

angularAMD.directive("criteriaManager", [
    "$sce",
    "$q",
    "baseUrlsFactory",
    "urlService",
    "multiLanguageService",
    "$rootScope",
    "$timeout",
    "assetStoreService",
    "collectionService",
    "helpers",
    "helperService",
    "modal",
    "datePickerHelper",
    "dataManagerService",
    "localChangesResolver",
    "User",
    "assetSelector",
    function (
        $sce,
        $q,
        baseUrlsFactory,
        urlService,
        multiLanguageService,
        $rootScope,
        $timeout,
        assetStoreService,
        collectionService,
        helpers,
        helperService,
        modal,
        datePickerHelper,
        dataManagerService,
        localChangesResolver,
        User,
        assetSelector
    ) {
        //Uses a smelly shared scope...in order to work with the bloated, hacky SpaMenu. One day it will become beautiful...
        return {
            //Scope gets passed:
            //type (String)
            //callback (Function)
            //edit (Boolean)
            //collectionWrapper (CollectionWrapperDto)
            //id (String)
            restrict: "E",
            template: template,
            controller: [
                "$scope",
                function ($scope) {
                    //Initialise variables ---------------------------------------------------------------------------------
                    $scope.multiLanguageService = multiLanguageService;
                    $scope.activeUserId = User.getId();
                    $scope.friendlyMainType = helperService.friendlyMainType;
                    $scope.collection = $scope.collection || null;
                    $scope.searchResult = [];
                    $scope.searchComplete = false;
                    $scope.inSidebar = true;
                    $scope.collectionCriteria = null;
                    $scope.primarySort = "LastModified";
                    $scope.reverseOrder = true;
                    $scope.imageInfo = informationIcon;
                    $scope.disableResponsesToFilter = !$scope.allowCriteriaResponsesTo && ($scope.collection === null || $scope.collection.Search.ResponsesTo === null);
                    $scope.overlay = angular.element(document.getElementsByClassName("builder-canvas-dark-layer")[0]);
                    $scope.viewData = {
                        deleting: false,
                        showEditMode: false,
                        hasCriteria: false,
                        listViewTemplate: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "collections/templates/collection.html"),
                        images: {
                            manual: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "collections/images/addAssets.svg"),
                            tag: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "collections/images/taggedAssets.svg"),
                            advanced: $sce.getTrustedResourceUrl(baseUrlsFactory.shared_component_base_url + "collections/images/advancedCriteria.svg")
                        },
                        typeTitles: {
                            manual: multiLanguageService.getString("collections.criteria.types.manual"),
                            tag: multiLanguageService.getString("collections.criteria.types.tagged_assets"),
                            advanced: multiLanguageService.getString("collections.criteria.types.advanced")
                        }
                    };

                    $scope.blankTarget = true;

                    $scope.onComplete = function (collectionDto) {
                        if ($scope.edit) {
                            saveCriteria(collectionDto);
                        } else {
                            $scope.callback(collectionDto);
                        }
                        $scope.onClose(true);
                    };

                    $scope.onClose = function (completed) {
                        if (!completed && $scope.edit) {
                            $scope.type = null;
                            $scope.viewData.showEditMode = true;
                            $scope.searchComplete = $scope.viewData.hasCriteria = false;
                            $scope.searchResult.length = 0;
                            return;
                        }
                        $scope.closePanel();
                    };

                    $scope.deleteCriteria = function (assetId, id, index) {
                        var isolatedScope = $scope.$new(true);
                        isolatedScope.onClose = function () {
                            isolatedScope.$destroy();
                        };

                        isolatedScope.confirmAction = function () {
                            $scope.viewData.deleting = true;
                            //Remove immediately from the view
                            $scope.collectionCriteria.splice(index, 1);
                            $scope.collectionWrapper.AssetCollections.splice($scope.collectionWrapper.AssetCollections.indexOf(assetId), 1);

                            var managerData = dataManagerService.getDto($scope.id).data;
                            if ($scope.collectionWrapper !== managerData) {
                                managerData.AssetCollections.splice(managerData.AssetCollections.indexOf(assetId), 1);
                            }

                            saveCollectionWrapper($scope.id).then(function () {
                                $rootScope.$broadcast(
                                    "collectionUpdated",
                                    $scope.id,
                                    {
                                        originalId: id,
                                        assetId: assetId
                                    },
                                    "delete"
                                );
                                $scope.viewData.deleting = false;
                                if ($scope.collectionWrapper.AssetCollections.length === 0) {
                                    $scope.closePanel();
                                }
                            });
                        };

                        modal.newModal({
                            scope: isolatedScope,
                            templateUrl: baseUrlsFactory.shared_component_base_url + "collections/templates/delete-criteria-message.lazy.html"
                        });
                    };

                    $scope.openInfoPanel = function (id, panel, actionData) {
                        panel = typeof panel !== "undefined" ? panel : "asset-info";
                        $rootScope.$broadcast("openRightHandSidePanel", {
                            panel: panel,
                            assetId: id,
                            actionData: actionData
                        });
                    };

                    $scope.closePanel = function () {
                        $rootScope.$broadcast("closeRightHandSidePanel");
                    };

                    $scope.getCollectionCriteria = function (id) {
                        collectionService.getCriteriaFromCollectionWrapper(id).then(function (response) {
                            $scope.viewData.showEditMode = true;

                            if (helpers.isNotEmptyArray(response.data)) {
                                $scope.collectionCriteria = response.data;
                                $scope.collectionCriteria.forEach(function (collectionDto) {
                                    collectionDto.Type = getCollectionCriteriaType(collectionDto);
                                });
                            }
                        });
                    };

                    $scope.editCollectionCriteria = function (collection, type) {
                        if (type === "manual") {
                            launchSelector(collection.Items.getValuesFromNestedKeys("Id"), collection);
                        } else {
                            $scope.viewData.showEditMode = false;
                            $scope.collection = collection;
                            $scope.type = type;
                            $scope.disableResponsesToFilter = !$scope.allowCriteriaResponsesTo && $scope.collection.Search.ResponsesTo === null;
                        }
                    };

                    $scope.getTitleFromType = function (type) {
                        return $scope.viewData.typeTitles[type];
                    };

                    $scope.getImageFromType = function (type) {
                        return $scope.viewData.images[type];
                    };

                    //Private Functions ------------------------------------------------------------------------------------
                    var criteriaUpdated = function (event, filterData) {
                        if ($scope.disableSearchResult) {
                            return;
                        }

                        $scope.cachedFilterData = filterData;
                        if (event) {
                            event.stopPropagation();
                        }
                        if (filterData) {
                            filterData.Ownership = ["ByMe"];
                            $scope.viewData.hasCriteria = true;
                            $scope.searchComplete = false;

                            collectionService.getCollectionResults($scope.id, filterData).then(function (response) {
                                $scope.searchComplete = true;
                                $scope.searchResult = response.data.TitleResults;

                                // Configure url
                                for (var i = 0, item; i < $scope.searchResult.length; i++) {
                                    item = $scope.searchResult[i];
                                    if (item.Id === $scope.id) {
                                        $scope.searchResult.splice(i, 1);
                                        i--;
                                    } else {
                                        item.url = urlService.configureAssetUrl(item.OpenUrl, "view");
                                        item.Created = datePickerHelper.normalizeDate(item.Created);
                                        item.LastModified = datePickerHelper.normalizeDate(item.LastModified);
                                    }
                                }
                            });
                        } else {
                            $scope.searchResult.length = 0;
                            $scope.viewData.hasCriteria = false;
                        }
                    };

                    $scope.$on("TagListUpdated", function (event, data) {
                        criteriaUpdated(null, $scope.cachedFilterData);
                    });

                    var getCollectionCriteriaType = function (collectionDto) {
                        var type = "tag";
                        if (collectionDto.StaticCollection) {
                            type = "manual";
                        } else if (
                            collectionDto.Search.SubType ||
                            collectionDto.Filter.Category ||
                            collectionDto.Search.ResponsesTo ||
                            collectionDto.Search.CreatedAfter ||
                            collectionDto.Search.CreatedBefore ||
                            collectionDto.Search.Keywords.length > 0
                        ) {
                            //Hacky workaround to check if the collectionDto is advanced or tagging...Store type on the backend instead?
                            type = "advanced";
                        }
                        return type;
                    };

                    var launchSelector = function (assetIds, collectionDto) {
                        $scope.activeCollection = collectionDto;
                        assetSelector
                            .selectAssets({
                                requiresWritePermission: true,
                                excludeIds: [$scope.id],
                                currentlySelectedIds: assetIds,
                                showSelected: true,
                                includeUpload: true
                            })
                            .then((assets) => {
                                if (assets === null) {
                                    return;
                                }

                                saveManualCriteria(assets);
                            })
                            .catch(angular.noop);
                    };

                    //Only used in Edit mode
                    var showSaving = function () {
                        $scope.overlay.addClass("show-overlay");
                    };

                    var hideSaving = function () {
                        $timeout(function () {
                            $scope.overlay.removeClass("save-complete");
                            $scope.overlay.removeClass("show-overlay");
                        }, TIMEOUT_CONSTANTS.SAVE_OVERLAY);
                    };

                    var saveManualCriteria = function (assets) {
                        if (!assets.getValuesFromNestedKeys("Id").isEqualTo($scope.activeCollection.Items.getValuesFromNestedKeys("Id"))) {
                            showSaving();
                            collectionService.addManualCriteriaToCollectionDto(assets, $scope.activeCollection);
                            collectionService.saveCollection($scope.activeCollection).then(function (response) {
                                $rootScope.$broadcast("manualCollectionUpdated", response.data.Asset);
                                postSave();
                                $scope.closePanel();
                            });
                        }
                        $scope.activeCollection = null;
                    };

                    var saveCriteria = function (collectionDto) {
                        showSaving();
                        collectionService.saveCollection(collectionDto).then(postSave);
                    };

                    var postSave = function () {
                        $rootScope.$broadcast("collectionUpdated", $scope.id);
                        //Update last modified date

                        saveCollectionWrapper($scope.id);
                        hideSaving();
                    };

                    var saveCollectionWrapper = function (id) {
                        var deferred = $q.defer(),
                            manager = dataManagerService.getDto(id),
                            save = manager.data.MainType === "AssetCollectionWrapper" ? manager.saveCollectionToServer.bind(manager) : manager.saveActivityLogToServer.bind(manager);

                        dataManagerService.checkBeforeSave(id, manager.data.MainType).then(function () {
                            save(id).then(function (data) {
                                data.promises.then(function () {
                                    var manager = dataManagerService.getDto(id);
                                    if (manager) {
                                        manager.is_saved = true;
                                    }

                                    dataManagerService.cleanupStorageOfAssetId(id);
                                    deferred.resolve(true);
                                });
                            });
                        });

                        return deferred.promise;
                    };

                    //Events -----------------------------------------------------------------------------------------------
                    $scope.$on("criteriaUpdated", criteriaUpdated);
                }
            ],

            link: function (scope, element, attr) {
                element.on("$destroy", function () {
                    scope.collectionCriteria = null;
                    scope.collectionWrapper = null;
                    scope.$destroy();
                });

                if (scope.edit && scope.id) {
                    scope.getCollectionCriteria(scope.id);
                }
            }
        };
    }
]);
