import { angularAMD } from "@pebblepad/amd";
import "../../services/assetLockingHelper.service";
import "../../../utilities/urlService";
import "../../../multiLanguageService/multiLanguageService";
import "../../../history/services/historyService";
import "../../../utilities/baseUrlsFactory";
import "../../../builder/dataManager/helperService";

angularAMD.controller("AssetLockedWarningController", AssetLockedWarningController);
AssetLockedWarningController.$inject = [
    "$scope",
    "$location",
    "$timeout",
    "$routeParams",
    "AssetLockingHelper",
    "urlService",
    "multiLanguageService",
    "historyService",
    "baseUrlsFactory",
    "helperService"
];

//Injectors
var $location = null,
    $timeout = null,
    $routeParams = null,
    AssetLockingHelper = null,
    UrlService = null,
    HistoryService = null;

function AssetLockedWarningController($scope, _$location_, _$timeout_, _$routeParams_, _AssetLockingHelper_, _UrlService_, multiLanguageService, _HistoryService_, baseUrlsFactory, helperService) {
    AssetLockingHelper = _AssetLockingHelper_;
    UrlService = _UrlService_;
    $location = _$location_;
    $timeout = _$timeout_;
    $routeParams = _$routeParams_;
    HistoryService = _HistoryService_;

    this._helperService = helperService;
    this.assetId = $scope.assetId;
    this.mainType = $scope.mainType;
    this.multiLanguageService = multiLanguageService;
    this.loading = false;
    this.stillInUse = false;
    this.lockedByUser = $scope.lockedByUser;
    this.lockedAsset = $scope.lockedAsset;
    this.hideModal = $scope.hideModal.bind($scope);
    this.onSuccess = $scope.onSuccess;
    this.onRedirect = $scope.onRedirect;
    this.lockedDetailsTemplate = baseUrlsFactory.shared_component_base_url + "assetLocking/directives/assetLockedWarning/templates/lock-details.html";

    const isAsset = !this._helperService.isResourceBuilder(this.mainType);
    const lockedHeadingKey = isAsset ? "locking.locked_asset.locked_heading" : "locking.locked_resource.locked_heading";
    const currentlyEditingKey = isAsset ? "locking.locked_asset.currently_editing" : "locking.locked_resource.currently_editing";

    this.lockedHeading = this.multiLanguageService.getString(lockedHeadingKey);
    this.currentlyEditing = this.multiLanguageService.getString(currentlyEditingKey);

    $scope.lockedByUser = null;
    $scope.lockedAsset = null;
    $scope.onSuccess = null;
    $scope.onRedirect = null;
    this.defineWarningLockDetails();
    $scope.$on("$destroy", this.onDestroy.bind(this));

    this.cancelAndRedirect = this._cancelAndRedirect.bind(this);
    this.preview = this._preview.bind(this);
    this.tryAgain = this._tryAgain.bind(this);
    this.cancel = this._cancel.bind(this);
}

AssetLockedWarningController.prototype.defineWarningLockDetails = function () {
    this.current_lock_reason = this.lockedAsset && this.assetId !== this.lockedAsset.AssetId;
};

AssetLockedWarningController.prototype.goToViewer = function () {
    this.onRedirect();
    return $location.path(UrlService.createUrl(this.assetId, null, true));
};

AssetLockedWarningController.prototype._tryAgain = function () {
    this.loading = true;

    //Try to lock asset!
    return AssetLockingHelper.setupLock(this.assetId, this.mainType).then(
        function () {
            this.hideModal();
            this.onSuccess();
        }.bind(this),
        function (lockedInfo) {
            if (lockedInfo && lockedInfo.LockGranted === false) {
                this.updateLockedInfo(lockedInfo);
            }

            return this.updateSpinnerState(true);
        }.bind(this)
    );
};

/**
 * @param {Object} lockedInfo
 */
AssetLockedWarningController.prototype.updateLockedInfo = function (lockedInfo) {
    if (this.lockedByUser.UserId !== lockedInfo.LockedBy.UserId) {
        this.lockedByUser = lockedInfo.LockedBy;
    }

    if (this.lockedAsset.AssetId !== lockedInfo.LockOriginatedOnAsset.AssetId) {
        this.lockedAsset = lockedInfo.LockOriginatedOnAsset;
        this.defineWarningLockDetails();
    }
};

/**
 * @param {boolean} stillInUse
 * @returns {Promise} - $timeout promise.
 */
AssetLockedWarningController.prototype.updateSpinnerState = function (stillInUse) {
    return $timeout(
        function () {
            this.loading = false;
            this.stillInUse = stillInUse;
        }.bind(this),
        1500
    );
};

AssetLockedWarningController.prototype._cancelAndRedirect = function () {
    $location.url(HistoryService.getLastHistoryPath($routeParams.historyId) || UrlService.createStoreRedirectUrl($location.path()));
    this.onRedirect();
    return this.hideModal();
};

AssetLockedWarningController.prototype._cancel = function () {
    this.onRedirect();
    return this.hideModal();
};

AssetLockedWarningController.prototype._preview = function () {
    this.goToViewer();
    this._cancel();
};

AssetLockedWarningController.prototype.onDestroy = function () {
    this.hideModal = null;
};
