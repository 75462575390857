export const showDialogWithoutStealingFocus = (dialog: HTMLDialogElement): void => {
    const currentFocusedElement = document.activeElement;

    if (currentFocusedElement !== null && dialog.contains(currentFocusedElement)) {
        dialog.showModal();
        (currentFocusedElement as HTMLElement).focus();
    } else {
        dialog.showModal();
    }
};
