import { angularAMD } from "@pebblepad/amd";
import { ButtonClass } from "@pjs/core-ui";
import "../utilities/baseUrlsFactory";
import "../multiLanguageService/multiLanguageService";
import "../progressDots/progressDots";
import "../utilities/touchMoveHandler.factory";
import "../utilities/shameOnBrowser";
import "../utilities/onRepeatEnd";
import "../userAgent/userAgent.service";
import template from "./templates/recovered-assets-guide.html";

angularAMD.directive("recoveredAssetsGuide", [
    "$sce",
    "$http",
    "baseUrlsFactory",
    "multiLanguageService",
    "$location",
    "$timeout",
    "TouchMoveHandler",
    "shameOnBrowser",
    "UserAgentService",
    function ($sce, $http, baseUrlsFactory, multiLanguageService, $location, $timeout, TouchMoveHandler, shameOnBrowser, UserAgentService) {
        var Stage = function (key, template) {
            this.key = key;
            this.template = $sce.getTrustedResourceUrl(template);
        };

        var styleClasses = {
            animateRight: "recovered-asset-guide__pane--animate-right",
            animateLeft: "recovered-asset-guide__pane--animate-left",
            changingState: "recovered-asset-guide__pane--hidden",
            active: "recovered-asset-guide__pane--active"
        };

        return {
            template: template,
            restrict: "E",
            /**
             * Expects:
             * onFinish - function
             * recoveredData - Object | Array
             */

            controller: [
                "$scope",
                "$element",
                "$attrs",
                function ($scope, $element, $attrs) {
                    //Variables --------------------------------------------------------------------------------------------
                    $scope.multiLanguageService = multiLanguageService;
                    $scope.buttonClass = ButtonClass;
                    $scope.recoveredSubmissions = [];
                    $scope.currentStage = 0;
                    $scope.elements = {
                        panes: []
                    };
                    $scope.stages = [
                        new Stage("initial", baseUrlsFactory.shared_component_base_url + "recoveredAssetsGuide/templates/recovered-initial-stage.html"),
                        new Stage("compare", baseUrlsFactory.shared_component_base_url + "recoveredAssetsGuide/templates/recovered-stage-one.html")
                    ];
                    $scope.viewData = {
                        hasConfirmed: false,
                        actions: {
                            assetStore: function () {
                                $location.url("/store?type=asset");
                            }
                        }
                    };

                    $scope.dateReady.then(function () {
                        if ($scope.submittedAssets.length) {
                            $scope.stages.splice(1, 0, new Stage("submitted", baseUrlsFactory.shared_component_base_url + "recoveredAssetsGuide/templates/submitted-stage.html"));
                            $scope.recoveredSubmissions = $scope.formatSubmissions($scope.submittedAssets);
                        }
                    });

                    //Methods ----------------------------------------------------------------------------------------------
                    // TODO: Move 'Stage' logic and transitions to service or directive! #future-refactor #code-cleanup
                    $scope.nextStage = function () {
                        if ($scope.currentStage < $scope.stages.length - 1) {
                            $scope.setStage($scope.currentStage + 1);
                        }
                        return $scope.currentStage;
                    };

                    $scope.setStage = function (index) {
                        var stage = $scope.stages[index],
                            lastStage = $scope.currentStage;
                        if (stage) {
                            $scope.currentStage = index;
                            $scope.updateView($scope.currentStage, lastStage);
                            $scope.animatePane($scope.currentStage, lastStage);
                        }

                        return $scope.currentStage;
                    };

                    $scope.formatSubmissions = function (submittedAssets) {
                        var formatted = [];

                        for (var i = 0, l = submittedAssets.length; i < l; i++) {
                            $scope.getRecoveredAssetsFromItem(submittedAssets[i], formatted);
                        }

                        return formatted;
                    };

                    $scope.getRecoveredAssetsFromItem = function (item, results) {
                        results = results || [];

                        for (var i = 0, l = item.RecoveredAssets.length; i < l; i++) {
                            results.push({
                                data: item.RecoveredAssets[i],
                                parent: item.MainParent
                            });
                        }

                        return results;
                    };

                    $scope.updateView = function (index, lastIndex) {
                        var newPane = $scope.elements.panes[index],
                            lastPane = $scope.elements.panes[lastIndex];

                        lastPane.removeClass(styleClasses.active);
                        newPane.addClass(styleClasses.active);
                    };

                    $scope.animatePane = function (index, lastIndex) {
                        var newPane = $scope.elements.panes[index],
                            styleClass = index > lastIndex ? styleClasses.animateRight : styleClasses.animateLeft;

                        newPane.addClass(styleClass);
                        shameOnBrowser.forceElementRepaint(
                            newPane[0],
                            function () {
                                $timeout(function () {
                                    newPane.removeClass(styleClass);
                                }, 10);
                            },
                            10
                        );
                    };

                    $scope.cachePaneElements = function () {
                        $scope.elements.panes = Array.prototype.slice.call($element[0].getElementsByClassName("recovered-asset-guide__pane"));
                        for (var i = 0, l = $scope.elements.panes.length; i < l; i++) {
                            $scope.elements.panes[i] = angular.element($scope.elements.panes[i]);
                        }

                        return $scope.elements.panes;
                    };

                    $scope.onTouchEnd = function (e, touchResults) {
                        if (touchResults.validX && !touchResults.validY) {
                            if (touchResults.xAxis === "right") {
                                $scope.nextStage();
                            } else {
                                $scope.setStage($scope.currentStage - 1);
                            }
                        }
                    };

                    $scope.initPanes = function () {
                        $scope.cachePaneElements();
                        $scope.elements.panes[$scope.currentStage].addClass(styleClasses.active);
                    };
                }
            ],

            link: function (scope, element, attrs) {
                if (UserAgentService.touch) {
                    var touchHandler = new TouchMoveHandler(element[0]);
                    touchHandler.registerEvents(null, null, scope.onTouchEnd, true);
                }
            }
        };
    }
]);
