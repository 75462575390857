"use strict";
import { angularAMD } from "@pebblepad/amd";
import "@pjs/components/overlay/services/overlayFactory";
import "@pjs/components/modal/services/modal";
import "@pjs/components/multiLanguageService/multiLanguageService";

angularAMD.service("UserStatusService", UserStatusService);
UserStatusService.$inject = ["$rootScope", "$http", "$templateCache", "baseUrlsFactory", "overlayFactory", "modal", "multiLanguageService"];
function UserStatusService($rootScope, $http, $templateCache, baseUrlsFactory, overlayFactory, modal, multiLanguageService) {
    // Public API
    // =============================================================================================================
    this.showModal = showModal;
    this.noNetwork = noNetwork;
    this.loggedOutWithUnsavedChanges = loggedOutWithUnsavedChanges;
    this.loggedOut = loggedOut;
    this.notAuthorisedToView = notAuthorisedToView;
    this.cacheModal = cacheModal;

    // Private methods
    // =============================================================================================================
    var modalUrl = baseUrlsFactory.shared_component_base_url + "modalDialogComponent/templates/generic-pop-up.lazy.html";

    function cacheModal() {
        var baseModalUrl = baseUrlsFactory.shared_component_base_url + "modal/templates/pop-up.html";

        $http.get(modalUrl).then(function (response) {
            $templateCache.put(modalUrl, response);
        }, cacheModalError);

        $http.get(baseModalUrl).then(function (response) {
            $templateCache.put(baseModalUrl, response);
        }, cacheModalError);
    }

    function cacheModalError(error) {
        throw new Error("Couldn't fetch error modal template: ", error);
    }

    function noNetwork(onClose) {
        $rootScope.$broadcast("spaMenuClosePanels");

        var dictionary = {
            heading: multiLanguageService.getString("builder.no_network_connection_modal.heading"),
            message: multiLanguageService.getString("builder.no_network_connection_modal.message"),
            button_label: multiLanguageService.getString("buttons.ok")
        };
        return showModal(dictionary, onClose);
    }

    function loggedOutWithUnsavedChanges(onClose) {
        var content = {
            title: multiLanguageService.getString("builder.login_to_save_modal.heading"),
            message: multiLanguageService.getString("builder.login_to_save_modal.message"),
            button: multiLanguageService.getString("builder.login_to_save_modal.button_label")
        };

        return showModal(content, onClose);
    }

    function loggedOut(onClose) {
        var content = {
            title: multiLanguageService.getString("builder.logged_out_modal.heading"),
            message: multiLanguageService.getString("builder.logged_out_modal.message"),
            button: multiLanguageService.getString("builder.logged_out_modal.button_label")
        };
        return showModal(content, onClose);
    }

    function notAuthorisedToView(onClose) {
        var content = {
            title: multiLanguageService.getString("builder.loading_error.generic_title"),
            message: multiLanguageService.getString("labels.viewer.no_permission_to_get_asset"),
            button: multiLanguageService.getString("buttons.ok")
        };
        return showModal(content, onClose);
    }

    function showModal(content, onClose) {
        var modalScope = $rootScope.$new();
        modalScope.title = content.title;
        modalScope.message = content.message;
        modalScope.button_label = content.button;

        modalScope.onClose = function () {
            modalScope.$destroy();
            onClose();
        };

        // Force the save overlay to be hidden to prevent multiple overlays being displayed when a user saves.
        overlayFactory.saveOverlay.hide();
        return modal.newModal({
            scope: modalScope,
            templateUrl: modalUrl,
            disableClose: true
        });
    }
}

export { UserStatusService };
