import { NgComponent } from "angular/ngComponent";
import { angularAMD } from "@pebblepad/amd";
import { mapCapabilityValueToLabel } from "../../mapCapabilityValueToLabel/mapCapabilityValueToLabel";
import "./answerHistoryAccordion/answerHistoryAccordion.component";
import "../../../multiLanguageService/multiLanguageService";
import "../../../utilities/pebbleDate";
import "../../../user/user.service";
import "../../../utilities/baseUrlsFactory";
import "../../../modal/services/modal";
import "../../../utilities/helpers";
import template from "./answer-history.html";

class AnswerHistoryComponent extends NgComponent {
    constructor($filter, $scope, baseUrlsFactory, modal, multiLanguageService, User, helpers) {
        super();

        this._$filter = $filter;
        this._$scope = $scope;
        this._baseUrlsFactory = baseUrlsFactory;
        this._modal = modal;
        this.multiLanguageService = multiLanguageService;
        this.helpers = helpers;
        this.userId = User.getId();
        this.historyTitle = this.multiLanguageService.getString("labels.answer_history.answer_history_title");
        this.historyItems = null;
        this.onEditJustification = this._editJustification.bind(this);
        this._setHistoryItems();
    }

    $onChanges(changes) {
        if (changes.answerHistory !== undefined && !changes.answerHistory.isFirstChange()) {
            this._setHistoryItems();
        }
    }

    _createAndSortCapabilityHistoryArray() {
        return this.answerHistory
            .sort((a, b) => new Date(b.LastUpdatedOn) - new Date(a.LastUpdatedOn))
            .map((item) => {
                const answer = mapCapabilityValueToLabel(this.originalElement, item.Answer);
                const previousAnswer = item.PreviousAnswer !== "" ? mapCapabilityValueToLabel(this.originalElement, item.PreviousAnswer) : undefined;

                return {
                    answer: answer,
                    justification: item.Justification,
                    lastUpdatedBy: item.LastUpdatedBy,
                    lastUpdatedByName: item.LastUpdatedByName,
                    lastUpdatedOnDate: this._$filter("pebbleDate")(item.LastUpdatedOn, "dateOnly"),
                    lastUpdatedOnTime: this._$filter("pebbleDate")(item.LastUpdatedOn, "timeOnly"),
                    originalItem: item,
                    previousAnswer: previousAnswer
                };
            });
    }

    _editJustification(historyItem) {
        let storedJustification = historyItem.justification;
        this._modal.launch(this._baseUrlsFactory.shared_component_base_url + "formComponents/justificationElement/templates/justification-modal.lazy.html", {
            elementDto: this.originalElement,
            isEditingJustification: true,
            isEmpty: (content) => !/\S/g.test(this.helpers.htmlToPlain(content)),
            isJustificationEmpty: !/\S/g.test(this.helpers.htmlToPlain(historyItem.justification)),
            justification: historyItem.justification,
            newAnswer: historyItem.originalItem.Answer,
            oldAnswer: historyItem.originalItem.PreviousAnswer,
            onUpdate: function (justification) {
                this.isJustificationEmpty = this.isEmpty(justification);
                this.justification = justification;
                storedJustification = justification;
            },
            onAfterOpen: function () {
                this.hasFullyRendered = true;
            },
            onConfirm: () => {
                this._updateJustification(storedJustification);
            },
            hasFullyRendered: false
        });
        this._$scope.$applyAsync();
    }

    _setHistoryItems() {
        this.historyItems = this._createAndSortCapabilityHistoryArray();
        this.historyTitle = this.multiLanguageService.getString("labels.answer_history.answer_history_title", { count: this.historyItems.length });
    }

    _updateJustification(justification) {
        this.onUpdateAnswerHistoryJustification(justification);
    }
}

const AnswerHistoryDefinition = {
    bindings: {
        titleId: "<",
        answerHistory: "<",
        viewMode: "<",
        originalElement: "<",
        onUpdateAnswerHistoryJustification: "<"
    },
    template: template,
    controller: AnswerHistoryComponent
};

AnswerHistoryComponent.$inject = ["$filter", "$scope", "baseUrlsFactory", "modal", "multiLanguageService", "User", "helpers"];

angularAMD.component("answerHistory", AnswerHistoryDefinition);

export { AnswerHistoryDefinition as answerHistory };
